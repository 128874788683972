<template>
    <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2 border-2 border-base-200 rounded-md p-4">
            <div class="text-xl font-bold">
                <label-component label="Api Keys" />
            </div>

            <div class="overflow-x-auto w-full h-96">
                <table class="table w-full">
                    <!-- head -->
                    <thead>
                        <tr>
                            <th>Identifier</th>
                            <th>Key</th>
                            <th>Expires</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- rows -->
                        <tr v-for="(apiKey, index) of apiKeys" v-bind:key="index">
                            <td>{{ apiKey.identifier }}</td>
                            <td>
                                <div class="flex flex-column gap-4 items-center">
                                    <button-component
                                        fa-icon="fa-regular fa-clipboard"
                                        @click="copyToClipboard(apiKey.api_key)"
                                    ></button-component>
                                    {{ apiKey.api_key.substring(0, 40) }}...
                                </div>
                            </td>
                            <td>{{ apiKey.expires_at ? toDate(apiKey.expires_at) : '/' }}</td>

                            <td>
                                <button-component
                                    fa-icon="fa-regular fa-trash"
                                    @click="deleteKey(apiKey._id)"
                                    background-color="bg-red-500"
                                ></button-component>
                            </td>
                        </tr>

                        <!-- show if no api keys exist -->
                        <tr v-if="apiKeys?.length === 0">
                            <td colspan="4" class="text-center">Keine Api-Keys hinterlegt</td>
                        </tr>
                    </tbody>

                    <!-- foot -->
                    <tfoot v-if="apiKeys?.length > 5">
                        <tr>
                            <th>Identifier</th>
                            <th>Key</th>
                            <th>Expires</th>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div v-if="!isMode('register')" class="flex flex-row gap-2 self-end">
                <button-component
                    fa-icon="fa-regular fa-plus"
                    background-color="bg-green-500"
                    @click="apiKeyModal = true"
                ></button-component>
            </div>
        </div>

        <!-- create api key -->
        <modal-component v-model="apiKeyModal">
            <template #body>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col items-center">
                        <!-- ICON -->
                        <i class="fa-regular text-4xl fa-key"></i>
                        <div class="text-secondary text-xl">Api Key erstellen</div>
                    </div>

                    <!-- CONTENT -->
                    <fieldset class="flex flex-col gap-4">
                        <div class="flex flex-col gap-1">
                            <label-component label="Identifier" postfix="(identifier)" />
                            <input-component
                                type="text"
                                placeholder="Identifier"
                                v-model="apiKey.identifier"
                            ></input-component>
                        </div>

                        <div class="flex flex-col gap-1">
                            <label-component label="Description" postfix="(description)" />
                            <input-component
                                type="text"
                                placeholder="Description"
                                v-model="apiKey.description"
                            ></input-component>
                        </div>

                        <div v-if="!apiKey.no_expiration" class="flex flex-col gap-1">
                            <label-component label="Ablaufdatum" postfix="(expires_at)" />
                            <input-component type="date" v-model="apiKey.expires_at"></input-component>
                        </div>

                        <div class="flex flex-row gap-2">
                            <input type="checkbox" v-model="apiKey.no_expiration" class="checkbox" />
                            <label-component label="Kein Ablaufdatum" />
                        </div>
                    </fieldset>
                </div>
            </template>

            <template #footer>
                <button-component @click="apiKeyModal = false"> Schließen</button-component>

                <button-component color="green" @click="save()"> Erstellen</button-component>
            </template>
        </modal-component>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import ButtonComponent from '@/components/ButtonComponent';
import ModalComponent from '@/components/ModalComponent.vue';
import LabelComponent from '@/components/LabelComponent.vue';
import InputComponent from '@/components/InputComponent.vue';
import Notifications from '@auth/vue-share/utility/notifications';
import { useApiKeysStore } from '@/stores/api-keys';
import moment from 'moment';

export default {
    name: 'ApiKeysTab',
    components: { LabelComponent, ModalComponent, ButtonComponent, InputComponent },
    props: ['subject', 'type', 'mode'],
    data() {
        return {
            apiKeys: [],
            apiKeyModal: false,
            apiKey: {}
        };
    },
    async mounted() {
        this.apiKeys = await this.apiKeysStore.get(this.subject);
    },
    computed: {
        ...mapStores(useApiKeysStore)
    },
    methods: {
        isMode(mode) {
            return this.mode === mode;
        },
        save() {
            if (this.apiKey?.no_expiration) this.apiKey.expires_at = null;

            if (!this.apiKey) {
                Notifications.error('Bitte fülle alle Felder aus');
                return;
            }

            if (this.apiKey.name === '') {
                Notifications.error('Bitte gibt einen API-Key Namen an');
                return;
            }

            if (this.apiKey.expires_at === '' && !this.apiKey.no_expiration) {
                Notifications.error('Bitte gibt ein Ablaufdatum an');
                return;
            }

            const requestPayload = {
                identifier: this.apiKey.identifier,
                sub: this.subject,
                sub_type: this.type
            };

            if (this.apiKey.description) {
                requestPayload.description = this.apiKey.description;
            }

            if (this.apiKey.expires_at) {
                requestPayload.expires_at = new Date(this.apiKey.expires_at).getTime();
            }

            this.apiKeysStore
                .create(requestPayload)
                .then((apikey) => {
                    Notifications.success('Api Key erfolgreich erstellt');
                    this.apiKeys.push(apikey);
                })
                .catch((err) => {
                    Notifications.error(`Api Key konnte nicht erstellt werden (${err.response.data})`);
                })
                .finally(() => {
                    this.apiKeyModal = false;
                    this.apiKey = {};
                });
        },
        deleteKey(id) {
            this.apiKeysStore
                .delete(id)
                .then(() => {
                    Notifications.success('Api Key erfolgreich gelöscht');
                    this.apiKeys = this.apiKeys.filter((apiKey) => apiKey._id !== id);
                })
                .catch((err) => {
                    Notifications.error(`Api Key konnte nicht gelöscht werden (${err.response.data})`);
                });
        },
        toDate(date) {
            return moment(date).format('DD.MM.YYYY');
        },
        copyToClipboard(text) {
            const input = document.createElement('input');
            input.setAttribute('value', text);
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            Notifications.success('Api Key wurde in die Zwischenablage kopiert');
        }
    }
};
</script>

<style scoped></style>
