<template>
    <div class="flex flex-col gap-4">
        <div class="flex flex-col items-center">
            <img :src="usersStore.getUserAvatar(user.mail)" alt="Profilbild" class="h-24 w-24 rounded-full shadow-xl" />
        </div>

        <!-- LOGIN INFORMATION -->
        <div class="flex flex-col gap-4 mt-2 border-2 border-base-200 rounded-md p-4">
            <div class="text-secondary text-2xl font-bold">Logindaten</div>

            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Login" postfix="(login) *" />
                <input-component type="text" placeholder="Login" v-model="user.login" />
            </div>

            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="E-Mail" postfix="(mail) *" />
                <input-component type="text" placeholder="E-Mail" v-model="user.mail" />
            </div>

            <div class="grid grid-cols-2 justify-between gap-4">
                <label-component label="Passwort" postfix="(password)" />
                <div class="flex flex-col gap-2">
                    <input-component type="text" placeholder="Passwort" v-model="user.password" />
                    <p class="text-xs">
                        Wird bei der Benutzererstellung kein Passwort angegeben, erhält der Benutzer eine E-Mail mit der
                        Aufforderung seinen Account zu aktivieren. Hier muss er dann selber ein Passwort definieren.
                    </p>
                </div>
            </div>
        </div>

        <!-- PROFILE INFORMATION -->
        <div class="flex flex-col gap-4 border-2 border-base-200 rounded-md p-4">
            <div class="text-secondary text-2xl font-bold">Accountinformationen</div>

            <!-- ADMIN -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Admin" postfix="(admin)" />

                <input type="checkbox" v-model="user.admin" placeholder="Admin" class="flex-grow rounded-md" />
            </div>

            <!-- DEACTIVATED -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Deaktiviert" postfix="(deactivated)" />

                <input
                    type="checkbox"
                    v-model="user.deactivated"
                    placeholder="Deaktiviert"
                    class="flex-grow rounded-md"
                />
            </div>

            <!-- NAME -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Name" postfix="(name)" />

                <input-component type="text" placeholder="Name" v-model="user.name" />
            </div>

            <!-- FIRSTNAME -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Vorname" postfix="(given_name) *" />

                <input-component type="text" placeholder="Vorname" v-model="user.given_name" />
            </div>

            <!-- FAMILY NAME -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Nachname" postfix="(family_name) *" />

                <input-component type="text" placeholder="Nachname" v-model="user.family_name" />
            </div>

            <!-- GENDER -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Geschlecht" postfix="(gender)" />

                <select class="input input-bordered w-full text-sm" v-model="user.gender">
                    <option disabled selected>Geschlecht auswählen</option>
                    <option
                        v-for="(gender, index) of genders"
                        v-bind:key="index"
                        :value="gender.value"
                        :selected="user.gender === gender.value"
                    >
                        {{ gender.name }}
                    </option>
                </select>
            </div>

            <!-- LOCALE -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Sprache" postfix="(locale) *" />

                <select class="input input-bordered w-full text-sm" v-model="user.locale">
                    <option disabled selected>Sprache auswählen</option>
                    <option
                        v-for="(language, index) of languages"
                        v-bind:key="index"
                        :value="language.value"
                        :selected="user.locale === language.value"
                    >
                        {{ language.name }}
                    </option>
                </select>
            </div>

            <!-- PHONE -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Telefonnummer" postfix="(phone_number)" />
                <input-component type="text" placeholder="Telefonnummer" v-model="user.phone_number" />
            </div>

            <!-- Company -->
            <div class="grid grid-cols-2 justify-between gap-4 items-center">
                <label-component label="Firma" postfix="(firmation)" />

                <input-component type="text" placeholder="Firma" v-model="user.firmation" />
            </div>

            <!-- Address -->
            <div class="grid grid-cols-1 justify-between gap-4 items-center">
                <div class="flex-grow text-lg font-bold text-secondary">Adresse</div>

                <div class="grid grid-rows-1 gap-4">
                    <div class="flex flex-col gap-1">
                        <label-component label="Straße" postfix="(street_address)" />
                        <input-component type="text" placeholder="Straße" v-model="user.street_address" />
                    </div>

                    <div class="flex flex-row gap-4">
                        <div class="grow flex flex-col gap-1">
                            <label-component label="Postleitzahl" postfix="(postal_code)" />
                            <input-component type="text" placeholder="Postleitzahl" v-model="user.postal_code" />
                        </div>

                        <div class="grow flex flex-col gap-1">
                            <label-component label="Stadt" postfix="(locality)" />
                            <input-component type="text" placeholder="Stadt" v-model="user.locality" />
                        </div>
                    </div>

                    <div class="flex flex-row gap-4">
                        <div class="grow flex flex-col gap-1">
                            <label-component label="Bundesland" postfix="(region)" />
                            <input-component type="text" placeholder="Bundesland" v-model="user.region" />
                        </div>

                        <div class="grow flex flex-col gap-1">
                            <label-component label="Land" postfix="(country)" />
                            <input-component type="text" placeholder="Land" v-model="user.country" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-end">
            <button-component fa-icon="fa-regular fa-floppy-disk" @click="save"></button-component>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useApplicationStore } from '@/stores/application';
import ButtonComponent from '@/components/ButtonComponent';
import InputComponent from '@/components/InputComponent';
import { useUsersStore } from '@/stores/users';
import LabelComponent from '@/components/LabelComponent';

export default {
    name: 'GeneralUserInfoTab',
    props: ['modelValue'],
    components: { LabelComponent, InputComponent, ButtonComponent },
    data() {
        return {
            user: undefined,
            genders: [
                {
                    name: 'Mann',
                    value: 'men'
                },
                {
                    name: 'Frau',
                    value: 'women'
                },
                {
                    name: 'Divers',
                    value: 'diverse'
                },
                {
                    name: 'Nicht angegeben',
                    value: 'not_specified'
                }
            ],
            languages: [
                {
                    name: 'Deutsch',
                    value: 'de-DE'
                },
                {
                    name: 'Englisch',
                    value: 'en-EN'
                }
            ]
        };
    },
    computed: {
        ...mapStores(useUsersStore, useApplicationStore)
    },
    created() {
        this.user = this.modelValue;
    },
    methods: {
        save() {
            console.log('save', this.user);
            this.$emit('save', this.user);
        }
    }
};
</script>

<style scoped>
.tabs-boxed .tab-active {
    color: white !important;
}
</style>
