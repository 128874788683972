<template>
    <div
        v-if="value"
        class="relative z-20 overflow-y-hidden"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
    >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-20 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <slot name="body"></slot>
                    </div>

                    <div class="bg-gray-50 px-4 py-3 flex flex-wrap gap-4 sm:px-6 justify-between">
                        <slot name="footer">
                            <button
                                type="button"
                                class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                @click="value = false"
                            >
                                Schließen
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalComponent',
    props: ['modelValue'],
    data() {
        return {
            value: false
        };
    },
    created() {
        this.value = this.modelValue;
    },
    watch: {
        modelValue(value) {
            this.value = value;
        },
        value(value) {
            this.$emit('update:modelValue', value);
        }
    }
};
</script>

<style scoped></style>
