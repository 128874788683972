<template>
    <div v-if="user" class="flex flex-col gap-4 text-secondary">
        <!-- TABS -->
        <div class="tabs tabs-boxed">
            <a
                v-for="(tab, index) of tabs"
                v-bind:key="index"
                class="tab text-secondary"
                :class="{ 'tab-active text-primary': tab.id === currentTab }"
                @click="currentTab = tab.id"
                >{{ tab.name }}</a
            >
        </div>

        <general-user-info-tab
            v-if="user"
            v-show="currentTab === 'general-user-info'"
            :mode="mode"
            v-model="user"
            @save="save"
        ></general-user-info-tab>
        <user-api-key-tab v-if="user" v-show="currentTab === 'user-api-keys'" :mode="mode" :user="user" @save="save">
        </user-api-key-tab>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useApplicationStore } from '@/stores/application';
import { useUsersStore } from '@/stores/users';
import GeneralUserInfoTab from '@/components/users/tabs/GeneralUserInfoTab.vue';
import UserApiKeyTab from '@/components/users/tabs/UserApiKeyTab.vue';

export default {
    name: 'UpsertUserComponent',
    components: { UserApiKeyTab, GeneralUserInfoTab },
    props: ['modelValue', 'mode'],
    data() {
        return {
            user: undefined,
            currentTab: 'general-user-info',
            tabs: [
                {
                    name: 'Allgemein',
                    id: 'general-user-info',
                    icon: 'fa-regular fa-gears'
                },
                {
                    name: 'Api Keys',
                    id: 'user-api-keys',
                    icon: 'fa-regular fa-key'
                }
            ]
        };
    },
    computed: {
        ...mapStores(useUsersStore, useApplicationStore)
    },
    created() {
        this.user = this.modelValue;
    },
    methods: {
        save(user) {
            this.$emit('save', user);
        },
        isMode(mode) {
            return this.mode === mode;
        }
    }
};
</script>

<style scoped>
.tabs-boxed .tab-active {
    color: var(--venus-color-primary) !important;
}
</style>
